import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React from 'react'

import LazyHydrate from 'react-lazy-hydration'

import { AppBar, Grid, NoSsr, Toolbar } from '@mui/material'

import Logo from 'app/assets/images/light-logo-header.svg'
import Box from 'app/components/base/Box'
import Stack from 'app/components/base/Stack'
import useAppStore, { AppStore } from 'app/store/app.store'
import useAuthStore, { getAuthState, getAuthToken } from 'app/store/auth.store'

import GetTheAppButton from '~/components/buttons/GetTheAppButton'
import CurrencyLangBlock from '~/components/common/CurrencyLangBlock'

const CartButton = dynamic(() => import('~/components/buttons/CartButton'), {
  ssr: true
})

const BurgerMenuButton = dynamic(
  () => import('~/components/buttons/BurgerMenuButton'),
  {
    ssr: true
  }
)

const HeaderUserBlock = dynamic(() => import('./HeaderUserBlock'), {
  ssr: false
})

const selector = (state: AppStore) => ({
  elasticClearState: state.elasticClearState
})

interface HeaderProps {
  onClickMenu: () => void
  isHideHeaderLogo?: boolean
}

const Header = ({ onClickMenu, isHideHeaderLogo }: HeaderProps) => {
  const { push, query } = useRouter()

  const isNativeApp = query?.isNativeApp as string

  const { elasticClearState } = useAppStore(selector)
  const token = useAuthStore(getAuthToken)
  const { initialized } = useAuthStore(getAuthState)

  const showHeaderUserBlock = token ? initialized : true

  const handleHomePage = () => {
    if (isNativeApp) return

    elasticClearState()
    push('/')
  }

  if (isNativeApp) {
    return null
  }

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(180deg, #102262 0%, #101340 100%)',
        boxShadow: '0px 4px 20px rgba(16, 34, 98, 0.15)'
      }}
    >
      <Toolbar
        variant="dense"
        sx={{
          px: { xs: 2, xl: 10 },
          minHeight: { cmd: 70 }
        }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            {...{ cmd: 4.3 }}
            lg={4}
            sx={{ display: { xs: 'none', cmd: 'block' } }}
          >
            <GetTheAppButton />
          </Grid>

          <Grid
            item
            xs={isNativeApp ? 12 : 8}
            sm={7}
            {...{ cmd: 1.7 }}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: {
                xs: isNativeApp ? 'center' : 'flex-end',
                cmd: 'center'
              }
            }}
          >
            <Box
              sx={{
                cursor: 'pointer',
                justifyContent: 'center',
                display: isHideHeaderLogo ? 'none' : 'flex',
                alignItems: 'center'
              }}
              onClick={handleHomePage}
            >
              <Logo />
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            sm={5}
            {...{ cmd: 6 }}
            sx={{ ...(isNativeApp && { display: 'none' }) }}
            lg={4}
          >
            <Stack
              direction="row"
              spacing={{ lg: 1 }}
              sx={{
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <NoSsr>{showHeaderUserBlock && <HeaderUserBlock />}</NoSsr>

              <CartButton />

              <BurgerMenuButton onClickMenu={onClickMenu} />

              <Box sx={{ display: { xs: 'none', cmd: 'block' } }}>
                <LazyHydrate whenVisible>
                  <CurrencyLangBlock textColor="light" />
                </LazyHydrate>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Header
