import { useRouter } from 'next/router'

import { FormattedMessage } from 'react-intl'

import { useTheme } from '@mui/material'

import Button from 'app/components/base/Button'

const GetTheAppButton = () => {
  const { push } = useRouter()
  const { palette } = useTheme()

  return (
    <Button
      variant="contained"
      size="small"
      onClick={() => push('/get-app')}
      sx={{
        width: 120,
        background: `linear-gradient(90deg, ${palette.secondary.light} 0%, ${palette.secondary.main} 100%)`,
        color: 'text.primary',
        border: 'none'
      }}
    >
      <FormattedMessage defaultMessage="Get the App" />
    </Button>
  )
}

export default GetTheAppButton
